import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';

import * as serviceWorker from './serviceWorker';
import Spinner from './Fallback-spinner';
import Store from './redux/storeConfig/store';
import reportWebVitals from './reportWebVitals';

import "./index.css"

const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={Store}>
    <Suspense fallback={<Spinner />}>
      <LazyApp />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
