import { combineReducers } from 'redux';

export const login = (state = { userRole: [''], login: false }, action) => {
  switch (action.type) {
    case 'LOGIN': {
      return { ...state, values: action.payload, login: true };
    }
    case 'LOGOUT': {
      return { ...state, values: action.payload, login: false };
    }
    case 'CHANGE_ROLE': {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};

export const register = (state = {}, action) => {
  switch (action.type) {
    case 'SIGNUP':
      return {
        ...state,
        values: action.payload,
      };
    default: {
      return state;
    }
  }
};

const authReducers = combineReducers({
  login,
  register,
});

export default authReducers;
